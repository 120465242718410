import 'jquery-validation';
// import faker from 'faker-br';

import Utils from '../utils.js';
import UTM from '../utm.js';

document.addEventListener('sebDocumentReady', function() {
	const scheduleVisitation = jQuery('.schedule-calendar .form-schedule');
	const rescheduleForm = jQuery("#reschedule .erro-dynamics")
	const loadingReschedule = $(".reschedule .form-schedule")
	const btnAvancar = document.querySelector('#btn-avancar');
	const btnSchedule = document.querySelector('#btn-reschedule')
	const step1Indicator = scheduleVisitation.find('.steps .step-1');
	const step2Indicator = scheduleVisitation.find('.steps .step-2');
	const step1 = scheduleVisitation.find('.form-box.step-1');
	const step2 = scheduleVisitation.find('.form-box.step-2');
	const dynamicsErrorMessage = scheduleVisitation.find('.erro-dynamics')
	const btnPrimary = document.querySelector(".agendamento .btn-primary")
	const btnSecondary= document.querySelector(".agendamento .btn-secondary")
	const contentText = document.querySelector(".agendamento.text-center")

	$("#datepicker-birth").datepicker({
		changeMonth: true,
		changeYear: true,
		yearRange: "-30:+0",
		maxDate: 0,
		dateFormat: "dd/mm/yy",
		selectOtherMonths: true,
		prevText: "Anterior",
		nextText: "Próximo",
		defaultDate: "-14y",
		monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", 
			"Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", 
						"Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", 
				"Quinta-feira", "Sexta-feira", "Sábado"],
		dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
		currentText: "Hoje",
		closeText: "Fechar",
	});
	
	
	let appointment_id = Utils.getCookie('appointment_id')
	
	let lead_id = ""
	let calendar_id
	let school_segment = ""
	let start_date_time = ""

	let unitsLoaded = false;
	let mbCalendar = []
	let listSegments = []

	function formatDateToFetch(date) {
		const new_date = date.split('/');
		return new_date[2] + '-' + new_date[1] + '-' + new_date[0];
	}

	function canRemoveIsLoading() {
		return !!(unitsLoaded);
	}

	function getParamsValue(param){
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has(param)) {
			return urlParams.get(param)
		}
	}

	function validateForm(form) {
		return form.validate({
			rules: {
				unit_schedule: {
					required: true,
				},
				school_segment: {
					required: listSegments.length > 0
				},
				first_name: {
					required: true,
				},
				last_name: {
					required: true,
				},
				email: {
					required: true,
                    customEmail: true,
					minlength: 3,
					maxlength: 100
				},
				phone: {
					required: true,
					minlength: 14,
					maxlength: 15
				},
				name_student: {
					required: true,
					minlength: 3,
					maxlength: 100
				},
				birth_student: {
					required: true,
					validDate: true
				},
				add_info: {
					required: false,
				},
				agree1 :{
					required: true
				},
				agree2 :{
					required: true
				},
				recaptcha_codigo: {
					required: true
				}
			},
		})
	}
	function setIsLoading(element) {
		element.addClass('is-loading')
	}

	function unsetIsLoading(element) {
		element.removeClass('is-loading')
	}

	function attachUnitListener(element) {

		const unitSelect = element.find('select[name="unit_schedule"]')
		unitSelect.on('change', function() {
			const segmentsSchool = $(this).find(":selected")
			const redirectSection = element.find('.to-show');
			const segments = $('select[name="school_segments"]');

			if(segmentsSchool.attr("data-segments")){
				element.find(".to-hide.segment").removeAttr("hidden")
				listSegments = String(segmentsSchool.data("segments"))
				listSegments = listSegments.includes(",") ? listSegments.split(",") : [listSegments]
			}else{
				listSegments = []
				element.find(".to-hide.segment").attr("hidden", "")
				segments.prop("disabled")
			}
			loadSegment(listSegments)
			unitSelect.removeClass("error")
			scheduleVisitation.find('#unit_schedule-error').attr("style", "display:none;")
			redirectSection.hide();
		})
	}

	function loadUnits(element) {
		let selectedUnits = jQuery('.schedule-calendar').data('selected-schools');
        
		selectedUnits = selectedUnits.replace(/\s+/g, '');
		selectedUnits = selectedUnits.replace(/\[/g, '');
		selectedUnits = selectedUnits.replace(/\]/g, '');
		selectedUnits = selectedUnits.replace(/'/g, '');

		setIsLoading(element)
		const csrfToken = Utils.getCookie('csrftoken');
		fetch(`/dynamics/mb/schools/?ids_schools=${formatSelectedSchools}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrfToken
			},
		}).then(response => response.json())
		.then(function(response) {

			const select = element.find('select[name="unit_schedule"]');

			let  filteredResponse;

			if(selectedUnits == 'None') {
				filteredResponse = response;
			} else {
				filteredResponse = response.filter((res) => selectedUnits.includes(res.mb_escolaid));
			}

			for(let i = 0; i < filteredResponse.length; i++) {

				const title = filteredResponse[i].mb_name;
				const value = filteredResponse[i].mb_escolaid;
				const calendar = filteredResponse[i].mb_mb_calendar_mb_escola[0];

				if(calendar){
					select.append(`<option data-segments=${calendar.mb_school_segment} data-calendar=${calendar.mb_calendarid} value=${value}>${title}</option>`);
				}else{
					select.append('<option value="'+value+'">'+title+'</option>');
				}
			}

			unitsLoaded = true;
			if(canRemoveIsLoading()) {
				unsetIsLoading(element)
			}
		})
	}

	function loadSegment(segments=[]){
		if(segments.length < 0) {
			$('select[name="school_segment"]').attr("disabled", true).empty()
		}else{
			const schoolSegments = {
				"108690000": "Infantil",
				"108690001": "Fundamental I",
				"108690002": "Fundamental II",
				"108690003": "Médio"
			}
			
			const select = $('select[name="school_segment"')
			select.removeAttr("disabled").empty()
			select.append(`<option value="" disabled selected></option>`)

			segments.map(segment => {
				$('select[name="school_segment"').append(`<option value=${segment}>${schoolSegments[segment]}</option>`)
			})
		}
	}

	function loadCalendar(element, school_segment) {

		const formData = {
			school_segment
		}

		if(appointment_id) {
			formData.appointment_id = appointment_id
			delete formData.calendar_id
		}else{
			formData.calendar_id = calendar_id
		}

		fetch('/dynamics/mb/check/calendar/', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRFToken': Utils.getCookie('csrftoken'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(formData),
		}).then(response => response.json())
		.then(function(response) {
			element.find('form .datepicker').datepicker('refresh')
			mbCalendar = response.data
			loadDatepicker()
			if(Object.keys(mbCalendar).length == 0) {
				const messageText = "No momento os nossos horários para autoagendamento estão reservados, nossa equipe entrará em contato para agendar um horário com você"
				$(".date-and-time .question:not(.secondary-question)").hide()
				if(btnSchedule){
					rescheduleForm.text(messageText)
					rescheduleForm.show()
				}else{
					$('#btn-schedule').hide()
				}
			}
			loadingReschedule.removeClass("is-loading")
		})
		.catch(e =>{
			console.log(("Error loaded calendar ", e.message));
			scheduleVisitation.removeClass("is-loading")
			dynamicsErrorMessage.text("Erro ao carregar o caléndario, tente novamente!")
			dynamicsErrorMessage.show()
		})
	}

	function getFormData(form, captcha) {
		return {
			first_name: form.find('[name="first_name"]').val(),
			last_name: form.find('[name="last_name"]').val(),
			email: form.find('[name="email"]').val(),
			phone: Utils.apenasNumeros(form.find('[name="phone"]').val()),
			student_fullname: form.find('[name="name_student"]').val(),
			student_birthday: formatDateToFetch(form.find('[name="birth_student"]').val()),
			school_interest: form.find('[name="unit_schedule"]').val(),
			description: form.find('[name="add_info"]').val(),
			lead_source: form.find('[name="origin"]').data("origin"),
			agree1: form.find('[name="agree1"]').is(':checked'),
			agree2: form.find('[name="agree2"]').is(':checked'),
			recaptcha_codigo: captcha
		};
	}

	function createLead() {
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const captcha = grecaptcha.getResponse();
		const form = jQuery(scheduleVisitation).find("form");
	  
		const validator = validateForm(form)

		const emailIsValid = emailPattern.test($("#email").val())

		scheduleVisitation.addClass('is-loading')

		if (validator.form() && emailIsValid) {

			const csrfToken = Utils.getCookie('csrftoken');
			console.log('Form is valid, submitting...');
		
			const utm_params = UTM.getUTM();
		
			const formData = {
				...utm_params,
				...getFormData(form, captcha),
			};
		
			fetch('/dynamics/mb/leads/', {
				method: 'POST',
				headers: {
				'Accept': 'application/json',
				'X-CSRFToken': csrfToken,
				'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			})
			.then(function(response) {
				return response.json();
			})
			.then(function(response) {
				if (response.success) {
					lead_id = response.lead_id;
					handleSteps();
					scheduleVisitation.removeClass('is-loading')
				}
			})
			.catch(e => {
				console.log("Erro created lead!", e.message);
				dynamicsErrorMessage.show();
				scheduleVisitation.removeClass('is-loading');
			});
		} else {
			console.log('Form is invalid, please check errors.');

			scheduleVisitation.find('.loading-mask').addClass("no-after");
			scheduleVisitation.find('.loading-mask #validate-error').removeClass("hidden");
			scheduleVisitation.addClass('is-loading')
			
			if(!emailIsValid && $("#email").val().length > 0){
				Utils.handleValidatedEmail()
			}
			if (!captcha) {
				form.find('.recaptcha-feedback').show();
				return;
			} else {
				form.find('.recaptcha-feedback').hide();
			}
		}
	}
	  

	function createSchedule(formSchedule) {
		const csrfToken = Utils.getCookie('csrftoken');

		if(start_date_time){
			fetch('/dynamics/mb/schedule/', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'X-CSRFToken': csrfToken,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formSchedule)
			}).then(function(response) {
				return response.json()
			}).then(function(response){
				if(response.success){
					Utils.setCookie("appointment_id", response.appointment_id)
					Utils.setCookie("school_segment", school_segment)
					const redirect_success = scheduleVisitation.find('[name="redirect_success"]');

					if(!redirect_success || redirect_success.length <= 0){
						window.location.href = "/pt/thankyou-lead-b2c/"
					}else{
						window.location.href = redirect_success.val();
					}
					scheduleVisitation.removeClass('is-loading');
				}else{
					if(!btnSchedule){
						scheduleVisitation.removeClass('is-loading');
						dynamicsErrorMessage.text(response.error)
						dynamicsErrorMessage.show()
					}else{
						loadingReschedule.removeClass('is-loading');
						btnSchedule.remove()
						rescheduleForm.text(response.error)
						rescheduleForm.show()
					}
				}
			}).catch(e => {
				console.log("Erro created schedule! ", e.message);
				scheduleVisitation.removeClass('is-loading');
				dynamicsErrorMessage.text("Erro ao criar um agendamento, tente novamente!")
				dynamicsErrorMessage.show()
			})
		}else{
			scheduleVisitation.removeClass('is-loading');
			dynamicsErrorMessage.text("Selecione a data e um horário para o agendamento!")
			dynamicsErrorMessage.show()
		}
	}

	function confirmSchedule(appointment_status, url){
		const csrfToken = Utils.getCookie('csrftoken');

		const formConfirm = {
			appointment_id,
			appointment_status
		}

		if(!appointment_id){
			formConfirm.appointment_id =  Utils.getCookie('appointment_id')
		}

		fetch('/dynamics/mb/confirm/', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'X-CSRFToken': csrfToken,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(formConfirm)
		}).then(function(response) {
			return response.json()
		}).then((response)=>{
			if(!response.errorMessage && response.success){
				if(appointment_status === "confirmed"){
					window.location.href = "/pt/agende-uma-visita/agendamento-confirmado/"
				}else{
					window.location.href = "/pt/agende-uma-visita/agendamento-nao-confirmado/"
				}
			}else{
				const text = contentText.querySelector("h1")
				text.textContent = response.errorMessage
				contentText.querySelector(".buttons").remove()
				contentText.append(text)
			}
			contentText.classList.remove("is-loading")
		}).catch(e => {
			dynamicsErrorMessage.show()
			scheduleVisitation.removeClass('is-loading');
		})
	}

	function handleSteps() {
		scheduleVisitation.addClass("is-loading")
		if(step1Indicator.hasClass('current')) {
			calendar_id = $('select[name="unit_schedule"]').find(':selected').data("calendar")
			school_segment = $('select[name="school_segment"').val()

			if(calendar_id){
				step1Indicator.removeClass('current');
				step2Indicator.addClass('current');
				step1.hide();
				step2.show();
				loadCalendar(scheduleVisitation, school_segment)
			}else{
				const redirect_success = scheduleVisitation.find('[name="redirect_success"]');
				window.location.href = redirect_success.val();
			}
			
		} else if(step2Indicator.hasClass('current')) {
			
			const firstRow = scheduleVisitation.find('.first-row');
			const formBoxes = scheduleVisitation.find('.form-box');
			const successMessage = scheduleVisitation.find('.step-success');
			firstRow.hide();
			formBoxes.hide();
			successMessage.show();

			scheduleVisitation.removeClass('is-loading');
		}
	}
	
	function convertUTCTimeToBRT(UTC_time_string) {
		if (!UTC_time_string) {
		  throw new Error("UTC time string is required");
		}
	  
		const UTC_date = new Date(UTC_time_string);
	  
		const BRT_offset = -3;
	  
		const BRT_date = new Date(UTC_date.getTime() + BRT_offset * 60 * 60 * 1000);
	  
		const formattedBRT_time = BRT_date.toLocaleTimeString();
	  
		return formattedBRT_time;
	}

	function isDateAvailable(date) {
		const year = date.getFullYear()
		const month = ("0" + (date.getMonth() + 1)).slice(-2)
		const day = ("0" + date.getDate()).slice(-2)

		const key = year + '-' + month + '-' + day

		const calendar = mbCalendar.reverse().filter(a=>{
			return a.date === key
		})
		
		return calendar.length > 0 ? calendar[0].date === key :false
	}

	function refreshHours() {
		start_date_time = ""
		const date = jQuery('form .datepicker').datepicker('getDate');

		const year = date.getFullYear();
		const month = ("0" + (date.getMonth() + 1)).slice(-2);
		const day = ("0" + date.getDate()).slice(-2);
		const key = year + '-' + month + '-' + day;

		const calendar = mbCalendar.reverse().filter(a=>{
			return a.date === key
		})[0]
		
		if(calendar){
			var hoursArray = calendar.serviceFreeDates 
		}

		const timeOptions = jQuery('.time-options');
		timeOptions.empty();
		
		hoursArray.forEach((hour, index) => {
			const convertHour = convertUTCTimeToBRT(`${key}T${hour}`)
			const option = `<label class="col-4 col-mb-4 w-100"><input type="radio" name="time" value="${`${key}T${hour}-03:00`}"/><span>${convertHour.substring(0, 5)}</span></label>`;
			timeOptions.append(option);
		});

		if (hoursArray.length > 0) {
			timeOptions.find('input[name="time"]').first().prop('checked', true);
		}
	}

	function loadDatepicker() {
		const firstAvailableDate = mbCalendar[0].date;

		$("#datepicker").datepicker({
			beforeShowDay: function(date) {
				return [isDateAvailable(date)]
			},
			onSelect: function(dateText) {
				refreshHours()
			},
			minDate: new Date(firstAvailableDate),
			dateFormat: 'dd/mm/yy',
			showWeek: true,
			monthNames: [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
			dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
		});

		setTimeout(function() {
			$("#datepicker .undefined:not(.ui-datepicker-unselectable) a").first().addClass("ui-state-active").attr("aria-current", "true");
			$("#datepicker .undefined:not(.ui-datepicker-unselectable) a").first().click();
		},200);
	}

	function loadCalendarReschedule() {
		const scheduleVisitationId = document.querySelector("#reschedule")
		school_segment = getParamsValue("segment") || Utils.getCookie("school_segment")
		appointment_id = getParamsValue("appointment_id") || Utils.getCookie("appointment_id")

		if (scheduleVisitationId) {
			console.log("Reschedule Loaded");
			loadCalendar(scheduleVisitation, school_segment)
		} 
	}

	if(btnPrimary){
		if(getParamsValue('appointment_id')) {
			loadingReschedule.addClass('is-loading');
			btnPrimary.removeAttribute("href")
			btnPrimary.onclick = function(e) {
				scheduleVisitation.addClass("is-loading")
				confirmSchedule("confirmed")
			}
		}
	}
	
	if(btnSecondary){
		if(getParamsValue('appointment_id')) {
			Utils.setCookie("appointment_id", getParamsValue('appointment_id'))
			Utils.setCookie("school_segment", getParamsValue('segment'))
			loadingReschedule.addClass('is-loading');
			btnSecondary.removeAttribute("href")
			btnSecondary.onclick = function(e) {
				scheduleVisitation.addClass("is-loading")
				confirmSchedule("canceled")
			}
		}
	}

	if(btnSchedule){

		if(!appointment_id || appointment_id == undefined || appointment_id == "undefined"){
			appointment_id = getParamsValue('appointment_id')
			school_segment = getParamsValue('segment')
			console.log('Get appointment and school segment');
		}

		btnSchedule.onclick = function(e) {
			
			const formSchedule = {
				school_segment,
				start_date_time,
				appointment_id
			}

			dynamicsErrorMessage.hide()
			loadingReschedule.addClass("is-loading")
			createSchedule(formSchedule)	
		}
	}

	$("#btn-validate-error").on("click", function(e) {
		e.preventDefault();
		scheduleVisitation.find('.loading-mask #validate-error').addClass("hidden");
		scheduleVisitation.removeClass("is-loading")
	})

	function loadBehaviorsInputs() {
		const formElement = document.querySelector('.form-schedule form');

		const inputName = formElement.querySelector('[name="first_name"]');
		const inputLastName = formElement.querySelector('[name="last_name"]');
		const inputEmail = formElement.querySelector('[name="email"]');
		const inputStudentName = formElement.querySelector('[name="name_student"]');
		const inputPhone = formElement.querySelector('[name="name_student"]');
		
		Utils.formatPhone(inputPhone)
		Utils.handleNameField(inputName);
		Utils.handleNameField(inputLastName);
		Utils.handleEmailField(inputEmail);
		Utils.handleNameField(inputStudentName);
	}

	document.addEventListener('click', function(event) {
	  if (event.target.matches('input[name="time"]')) { 
		const selectedTime = event.target.value;
		start_date_time = selectedTime
	  }
	});

	step1Indicator.on('click', function() {
		if(step2Indicator.hasClass('current')) {

			step1Indicator.addClass('current');
			step2Indicator.removeClass('current');
			step1.show();
			step2.hide();

			grecaptcha.reset()
		}
	})

	if(appointment_id){
		loadingReschedule.addClass("is-loading")
		loadCalendarReschedule()
	}
	
	loadBehaviorsInputs()
	
	scheduleVisitation.each(function(index) {

		const current = jQuery(this);
		const form = jQuery(this).find('form');

		loadUnits(current)
		attachUnitListener(current)

		if(btnAvancar != null) {
			btnAvancar.onclick = function(event) {
				// calendar_id = $('select[name="unit_schedule"]').find(':selected').data("calendar")
				setIsLoading(scheduleVisitation)
				createLead()
			};
		}

		form.validate({
			errorPlacement: function(error, element) {

				let type = element.attr('type');

				if(element.parent().hasClass('form-control')) {

					error.insertAfter(element.parent());
					return;
				}
				if(element.get(0).tagName.toLowerCase() == 'select') {

					element.parent().append(error)
					return;
				}
				if(type == 'checkbox') {

					let parent = element.closest('.checkbox-container');
					error.css('order', parent.css('order'));
					error.insertAfter(parent);
					return;
				}

				error.insertAfter(element);
			},
			submitHandler: function() {

				const formSchedule = {
					calendar_id,
					school_segment,
					lead_id, 
					start_date_time
				}

				dynamicsErrorMessage.hide()
				
				scheduleVisitation.addClass('is-loading');
				
				createSchedule(formSchedule)	

				return false;
			},
		});
	});
});